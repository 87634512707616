import {
  number,
  string,
  union,
  object,
  nullish,
  array,
  boolean,
  type Input,
  parse,
} from "valibot";
import useHttp from "~/composables/useHttp";
import { useHandleError } from "~/composables/useHandleError";

const attributesSpecialIconSchema = object({
  title: string(),
  buttonText: string(),
  icon: string(),
  targetUrl: string(),
  description: string(),
});

const specialIconSchema = object({
  attributes: nullish(attributesSpecialIconSchema),
  id: union([string(), number()]),
  type: string(),
});

const responseSpecialIconSchema = object({
  data: nullish(array(specialIconSchema)),
  message: nullish(string()),
  success: boolean(),
});

export type ResponseSpecialIcon = Input<typeof responseSpecialIconSchema>;

export async function getSpecialIcon({
  endPointApi,
  cityId,
}: {
  endPointApi?: string;
  cityId?: number | string;
}) {
  const errorPath = "api/getSpecialIcon";
  const defaultErrorMessage =
    "Oops, something went wrong, failed get icon special";
  try {
    const apiUrl = endPointApi || `/hungryhub_specials.json?city_id=${cityId}`;
    const { data, error } = await useHttp({
      url: `${apiUrl}`,
      method: "GET",
    });

    if (error.message && !data) {
      if (error.shouldReport) {
        useHandleError({
          errorLevel: "error",
          defaultErrorMessage: error.message || defaultErrorMessage,
          errorPath,
        });
      }
      return {
        success: false,
        message: error.message,
        data: [],
      };
    }

    const {
      success,
      message,
      data: responseData,
    } = parse(responseSpecialIconSchema, data);

    if (!success) {
      return {
        success: false,
        message,
        data: [],
      };
    }

    return {
      success: true,
      message,
      data: responseData,
    };
  } catch (error) {
    const message = useHandleError({
      err: error,
      defaultErrorMessage,
      errorPath,
      errorLevel: "error",
    });
    return {
      isSuccess: false,
      message,
      data: [],
    };
  }
}

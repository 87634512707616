<template>
  <section name="icon section">
    <div v-if="isSwiperLoaded" class="">
      <component
        :is="swiperComponent.main.value"
        class="swiper"
        :navigation="{
          nextEl: `.top-categories-slider .slider-next`,
          prevEl: `.top-categories-slider .slider-prev`,
        }"
        :modules="[
          swiperModule.navigation.value,
          swiperModule.pagination.value,
        ]"
        :slides-per-view="slidePerView"
        :space-between="10"
        :center-insufficient-slides="true"
        @slide-change="onSlideChange"
      >
        <component
          :is="swiperComponent.slide.value"
          v-for="(icon, key) in specialIcons"
          :key="key"
          class="swiper-slide icon-slide"
        >
          <CardIcon
            :title="icon.attributes?.title || ''"
            :target-url="icon.attributes?.targetUrl || ''"
            :icon="icon.attributes?.icon || ''"
            :position="key + 1"
            @on-special-clicked="specialIconOnClicked"
          />
        </component>
      </component>
    </div>
  </section>
</template>

<script lang="ts" setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "~/assets/css/swiper.scss";
import { ref, defineAsyncComponent, type PropType } from "vue";
import useSwiper from "~/composables/useSwiper";
import { type ResponseSpecialIcon } from "~/api/common/getSpecialIcon";
import type { HomepageModelEvent } from "~/types/Home";

defineProps({
  slidePerView: {
    type: [Number, String],
    required: true,
  },
  specialIcons: {
    type: Array as PropType<ResponseSpecialIcon["data"]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits<{
  (e: "on-special-clicked", data: HomepageModelEvent): void;
}>();

const { loadSwiper, swiperComponent, swiperModule, isSwiperLoaded } =
  useSwiper();

const isReachEnd = ref(false);
const isReachStart = ref(true);

const CardIcon = defineAsyncComponent(
  () => import("~/section/home/IconSection/CardIcon.vue")
);

const onSlideChange = (val: any) => {
  isReachStart.value = val.isBeginning;
  isReachEnd.value = val.isEnd;
};

await loadSwiper();

function specialIconOnClicked(data: HomepageModelEvent) {
  emits("on-special-clicked", data);
}

defineOptions({
  name: "IconSliderMain",
});
</script>
<style lang="scss">
.icon-slide {
  width: 160px !important;
  height: 130px !important;
  @apply mr-2;
}

.icon-slide:first-child {
  @apply ml-4;
}
</style>
<i18n>
  {
    "en": {
      "freeDelivery": "Free Delivery",
      "recommendedStaycations": "Recommended Staycations",
      "acceptGiftCardCodes": "Accept Gift Card & Codes",
      "comeMorePayLess": "Come More Pay Less",
      "mostLovedByUsers": "Most Loved By Users",
      "voucher": "Voucher"
    },
    "th": {
      "recommendedStaycations": "สเตเคชั่นที่แนะนำ",
      "acceptGiftCardCodes": "ร้านที่รับกิ๊ฟการ์ดและคูปองส่วนลด",
      "comeMorePayLess": "ยิ่งมาเยอะ ยิ่งจ่ายน้อย",
      "mostLovedByUsers": "ร้านที่ใคร ๆ ก็ชอบ",
      "voucher": "ส่วนลด",
      "freeDelivery": "ฟรีค่าจัดส่ง"
    }
  }
  </i18n>

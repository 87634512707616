<template>
  <div class="relative">
    <div class="icon-slider swiper">
      <div class="swiper-wrapper mb-10 flex flex-row gap-x-2">
        <div
          v-for="index in props.loadingPerItem"
          :key="index"
          class="icon-item rounded-xl border border-gray-300 p-4"
        >
          <div class="flex flex-col">
            <div class="h-[42px] w-[42px] bg-gray-500"></div>

            <div class="mt-4 h-[10px] w-full bg-gray-500"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  loadingPerItem: {
    type: [Number, String],
    required: true,
  },
});

defineOptions({
  name: "IconSliderLoading",
});
</script>

<style lang="scss">
.icon-slider {
  .swiper-slide {
    width: calc(100% / 4);

    @screen lg {
      width: calc(100% / 7);
    }
  }
}

.icon-item {
  width: 160px;
  height: 130px;

  @screen lg {
    width: 318px;
    height: 340px;
  }
}
</style>

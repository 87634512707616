<template>
  <template v-if="props.isLoading">
    <IconSliderLoading :loading-per-item="2" />
  </template>
  <template v-else>
    <IconSliderMain
      v-bind="props"
      @on-special-clicked="(data: HomepageModelEvent) => emits('on-special-clicked', data)"
    />
  </template>
</template>

<script lang="ts" setup>
import { type PropType } from "vue";
import IconSliderLoading from "./IconSliderLoading.vue";
import IconSliderMain from "./IconSliderMain.vue";
import { type ResponseSpecialIcon } from "~/api/common/getSpecialIcon";
import type { HomepageModelEvent } from "~/types/Home";

const props = defineProps({
  specialIcons: {
    type: Array as PropType<ResponseSpecialIcon["data"]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  slidePerView: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits<{
  (e: "on-special-clicked", data: HomepageModelEvent): void;
}>();

defineOptions({
  name: "IconSlider",
});
</script>

<template>
  <div ref="target" class="flex flex-col gap-y-[8px]">
    <HomeSectionTitle v-if="!isLoading" :title="punchLine || title" />
    <IconSlider
      :special-icons="specialIcons"
      :slide-per-view="slidePerView"
      :is-loading="isLoading"
      @on-special-clicked="(data: HomepageModelEvent) => emits('on-special-clicked', data)"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, toRefs } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";
import IconSlider from "~/section/home/IconSection/IconSlider.vue";
import alert from "~/lib/alert";
import {
  getSpecialIcon,
  type ResponseSpecialIcon,
} from "~/api/common/getSpecialIcon";
import type { HomepageModelEvent } from "~/types/Home";

const props = defineProps({
  slidePerView: {
    type: [Number, String],
    required: true,
  },
  endPointApi: {
    type: String,
    default: "",
  },
  punchLine: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});

const emits = defineEmits<{
  (e: "on-special-clicked", data: HomepageModelEvent): void;
}>();

const { endPointApi } = toRefs(props);

const target = ref(null);
const isVisible = ref(false);
const isLoading = ref(true);
const specialIcons = ref<ResponseSpecialIcon["data"]>([]);

const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      isVisible.value = isIntersecting;
      stop();
    }
  }
);

async function getListSpecialIcon() {
  isLoading.value = true;
  const { data, success, message } = await getSpecialIcon({
    endPointApi: endPointApi.value,
  });
  if (!success) {
    if (message) {
      alert.error(message);
    }
  }

  if (data && data.length) {
    specialIcons.value = data;
    isLoading.value = false;
  }
}

onMounted(() => {
  watch(isVisible, (newValue) => {
    if (newValue) {
      getListSpecialIcon();
    }
  });
});
</script>
